
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('org_pro.service') }}</h4>
      </template>
      <template v-slot:body>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.component')"
                    label-for="component_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.component_id"
                    :options="componentList"
                    id="component_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.module')"
                    label-for="module_id"
                    >
                    <b-form-select
                    plain
                    v-model="search.module_id"
                    :options="moduleList"
                    id="module_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col  xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('org_pro.service_namel')"
                    label-for="service_name"
                    >
                    <b-form-input
                        id="service_name"
                        v-model="search.service_name"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
            </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('org_pro.service') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table thead-class="table_head" bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                  <template v-slot:cell(index)="data">
                    {{ $n(data.index + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(component_name)="data">
                    <span class="capitalize">{{ data.item.component_name }}</span>
                  </template>
                  <template v-slot:cell(module_name)="data">
                    <span class="capitalize">{{ data.item.module_name }}</span>
                  </template>
                  <template v-slot:cell(service_name)="data">
                    <span class="capitalize">{{ data.item.service_name }}</span>
                  </template>
                  <template v-slot:cell(sorting_order)="data">
                    <span class="capitalize">
                      <input @change="checkSortOrder(data.item,$event)"  type="number" :value="data.item.sorting_order"  style="width: 100px;margin:auto" class="form-control text-center"/>
                    </span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge" :class="data.item.status ? 'badge-danger' : 'badge-success'">{{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Change Status" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Change Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                  </template>
                  <template #custom-foot="">
                    <b-tr>
                      <b-th colspan="4"></b-th>
                      <b-th class="text-center">
                        <button class="btn btn-primary btn-sm" v-on:click="SubmitData()">
                          <i class="fa fa-paper-plane"></i>Submit
                        </button>
                      </b-th>
                      <b-th colspan="2"></b-th>
                    </b-tr>
                  </template>
                </b-table>
                <b-pagination
                  align="center"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                  />
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId" :organizatinData="organizations"/>
      </p>
    </b-modal>
    <!-- <pre>{{datas}}</pre> -->
  </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { serviceList, serviceToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import OrderCheckModule from '@/Utils/common'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        FormV
    },
    data () {
      return {
        search: {
          component_id: 0,
          module_id: 0,
          service_name: ''
        },
        rows: [],
        organizations: [],
        moduleList: [],
        datas: [],
        isSave: false,
        serviceListData: this.$store.state.commonObjCommonConfig.serviceList
      }
    },
    computed: {
      componentList: function () {
        const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
        return tmpList
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('org_pro.service_entry') : this.$t('org_pro.service') + ' ' + this.$t('globalTrans.modify')
      },
      columns () {
          const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.component_namel'), class: 'text-center' },
          { label: this.$t('org_pro.module_namel'), class: 'text-center' },
          { label: this.$t('org_pro.service_namel'), class: 'text-center' },
          { label: this.$t('org_pro.sorting_order'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-10' }
          ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'component_name_bn' },
            { key: 'module_name_bn' },
            { key: 'service_name_bn' },
            { key: 'sorting_order' },
            { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'component_name' },
            { key: 'module_name' },
            { key: 'service_name' },
            { key: 'sorting_order' },
            { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
        loadingState: function (newVal) {
        if (newVal) {
            this.loadData()
        }
        },
        'search.component_id': function (newVal) {
        this.moduleList = this.getModuleList(newVal)
        }
    },
    async created () {
        this.loadData()
    },
    methods: {
      checkSortOrder: function (item, event) {
        item.old_serial = item.sorting_order
        item.sorting_order = event.target.value
        if (item.module_id) {
          const returnData = OrderCheckModule.OrderCheck(item, this.serviceListData, this.isSave, 'service')
          this.serviceListData = returnData.moduleListData
          this.isSave = returnData.isSave
        }
      },
      async searchData () {
        this.loadData()
      },
      remove (item) {
        this.changeStatus(commonServiceBaseUrl, serviceToggleStatus, item, 'common_config', 'serviceList')
      },
      details (item) {
        this.serviceid = item.id
        this.serviceDetails = item.org
      },
      loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(commonServiceBaseUrl, serviceList, params).then(response => {
            if (response.success) {
              this.$store.dispatch('setList', response.data.data)
              this.paginationData(response.data)
              const allData = this.$store.state.list
              this.datas = JSON.parse(JSON.stringify(allData))
            }
        })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      getModuleList (componentId) {
        const moduleList = this.$store.state.commonObjCommonConfig.moduleList.filter(item => item.status === 0)
        if (componentId) {
            const moduleData = moduleList.filter(module => module.component_id === componentId)
            const tmpList = moduleData.map((obj, index) => {
              if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
              } else {
                return { value: obj.value, text: obj.text }
              }
            })
            return tmpList
        }
      },
      async confirmData () {
        let result = null
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        result = await RestApi.postData(commonServiceBaseUrl, 'master-menu/change-serial-order/MasterService', this.datas)
        if (result.success) {
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
          this.$toast.success({
            title: 'Success',
            message: 'Data saved successfully',
            color: '#D6E09B'
          })
          this.loadData()
        }
      },
      async SubmitData () {
        this.$swal({
          title: this.$t('globalTrans.recommendentChangeMsg'),
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          focusConfirm: false
        }).then((resultSwal) => {
          if (resultSwal.isConfirmed) {
            this.confirmData()
          }
        })
      }
    }
}
</script>
