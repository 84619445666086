<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="9" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Component" vid="component_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="component_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro.component')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="service.component_id"
                      :options="componentList"
                      id="component_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Module" vid="module_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="module_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro.module')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="service.module_id"
                      :options="moduleList"
                      id="module_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Service Name (En)" vid="service_name" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="description"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                      {{ $t('org_pro.service_name')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="description"
                      v-model="service.service_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Service Name (Bn)" vid="service_name_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="service_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                      {{ $t('org_pro.service_name_bn')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="service_name_bn"
                      v-model="service.service_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('org_pro.sorting_order')"
                  label-for="sorting_order"
                >
                  <template v-slot:label>
                    {{ $t('org_pro.sorting_order')}} <span class="text-danger">*</span>
                  </template>
                  <input @change="checkSortOrder(service,$event)" :id="service.id" type="text" :value="service.sorting_order" class="form-control"/>
                </b-form-group>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { serviceStore, serviceUpdate } from '../../api/routes'
import OrderCheckModule from '@/Utils/common'
export default {
  props: ['id', 'organizatinData'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getServiceData()
      // Object.freeze(tmp)
      this.service = tmp
    }
    this.organizationList = this.organizatinData
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      service: {
        component_id: 0,
        module_id: 0,
        service_name: '',
        service_name_bn: ''
      },
      moduleList: [],
      isSave: true,
      serviceListData: this.$store.state.commonObjCommonConfig.serviceList
    }
  },
  computed: {
    componentList: function () {
      const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
      return tmpList
    }
  },
  watch: {
    'service.component_id': function (newVal, oldVal) {
      this.moduleList = this.getModuleList(newVal)
    },
    'service.module_id': function (newVal, oldVal) {
      this.getLastOrder()
    }
  },
  methods: {
    checkSortOrder: function (item, event) {
      item.old_serial = item.sorting_order
      item.sorting_order = event.target.value
      if (item.module_id) {
        const returnData = OrderCheckModule.OrderCheck(item, this.serviceListData, this.isSave, 'service')
        this.serviceListData = returnData.moduleListData
        this.isSave = returnData.isSave
      }
    },
    getServiceData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.service.old_serial = this.service.sorting_order
      const returnData = OrderCheckModule.OrderCheck(this.service, this.serviceListData, this.isSave, 'service')
      if (!returnData.isSave) {
        OrderCheckModule.errorMessageShow(this.service)
        return false
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.service.id) {
        result = await RestApi.putData(commonServiceBaseUrl, `${serviceUpdate}/${this.id}`, this.service)
      } else {
        result = await RestApi.postData(commonServiceBaseUrl, serviceStore, this.service)
      }
      loadingState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.$toast.success({
        title: this.$t('globalTrans.success'),
        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
        color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getModuleList (componentId = null) {
      const moduleList = this.$store.state.commonObjCommonConfig.moduleList.filter(item => item.status === 0)
      if (componentId) {
        const moduleData = moduleList.filter(module => module.component_id === componentId)
        const tmpList = moduleData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
        return tmpList
      }
    },
    getLastOrder: function () {
      if (!this.service.id) {
        const serviceListDataCheck = this.$store.state.commonObjCommonConfig.serviceList.filter(item => item.module_id === this.service.module_id)
        this.serviceListDataCheck.sort((a, b) => { return a.sorting_order - b.sorting_order })
        let lastOrder = 0
        serviceListDataCheck.forEach((element, key) => {
          lastOrder = element.sorting_order
        })
        this.service.sorting_order = lastOrder + 1
      }
    }
  }
}
</script>
